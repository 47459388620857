<template>
	<div class="about" v-cloak>
		<div class="AboutTxt">
			<div class="container" v-if="orderInfo.Order">
				<div class="FullPiceBox">
					<img src="../../public/img/CheckIconActive.png" class="SucessIcon">
					<h1 class="moblieLittle">
						Thanks, {{orderInfo.Order.Contact}}. Your quote #{{orderInfo.Order.OrderNo}} from iMover has been generated!
						Please check the following details for your quote:
					</h1>
					<!--<p>
  						Thanks, John. Your quote #292129  from iMover has been generated! Please check the following details for your quote:
  					</p>-->
				</div>
				<div class="SucessBox">
					<h1 class="moblieLittle" v-if="orderInfo.Order.SubmitType.value==2&&orderInfo.Order.SubmitType.value==3">
						Your booking request</br>
						Reference No. {{orderInfo.Order.OrderNo}}
					</h1>
					<h1 class="moblieLittle" v-else>
						Reference No. {{orderInfo.Order.OrderNo}}
					</h1>
					<div class="BookBox">
						<div class="BookBoxLi">
							<div class="BookBoxLiHread">
								Move Type
							</div>
							<div class="BookBoxLiBody">
								<div class="BodyIcon">
									<img src="/img/bookicon_01.png">
								</div>
								<h1>{{orderInfo.Order.QuoteType.text}}</h1>
								<p>Bedroom house</p>
								<p>4 storeys</p>
							</div>
						</div>
						<div class="BookBoxLi">
							<div class="BookBoxLiHread">
								Vehicle
							</div>
							<div class="BookBoxLiBody">
								<div class="BodyIcon">
									<img src="/img/bookicon_02.jpg">
								</div>
								<!-- <h1>{{orderInfo.Order.VehicleQty}}</h1> -->
								<h1>{{orderInfo.Order.VehicleModel}}</h1>
								<!-- <p></p> -->
							</div>
						</div>
						<div class="BookBoxLi">
							<div class="BookBoxLiHread">
								Staff
							</div>
							<div class="BookBoxLiBody">
								<div class="BodyIcon">
									<img src="/img/bookicon_03.jpg">
								</div>
								<h1>{{orderInfo.Order.Mover}}</h1>
								<!-- <p>Movers</p> -->
							</div>
						</div>
						<div class="BookBoxLi">
							<div class="BookBoxLiHread">
								Date
							</div>
							<div class="BookBoxLiBody">
								<div class="BodyIcon">
									<img src="/img/bookicon_04.jpg">
								</div>
								<h1>{{orderInfo.Order.BookDateTime.substring(0,10)}}</h1>
								<!-- <h2>Move Date</h2> -->
							</div>
						</div>
						<div class="BookBoxLi">
							<div class="BookBoxLiHread">
								Hourly Rate
							</div>
							<div class="BookBoxLiBody">
								<div class="BodyIcon">
									<img src="/img/bookicon_05.jpg">
								</div>
								<h1>{{orderInfo.Order.HourlyRate}}/h </h1>
								<!-- <h2>Hourly Rate</h2> -->
							</div>
						</div>
						<div class="BookBoxLi" v-if="submittype!=2">
							<div class="BookBoxLiHread">
								Job Time
							</div>
							<div class="BookBoxLiBody">
								<div class="BodyIcon">
									<img src="/img/bookicon_06.jpg">
								</div>
								<h1>{{orderInfo.Order.JobMinHour}}-{{orderInfo.Order.JobMaxHour}}h </h1>
								<!-- <h2>Job Time</h2> -->
							</div>
						</div>
						<div class="BookBoxLi" v-if="orderInfo.Order.TravelTimeCharge!=0">
							<div class="BookBoxLiHread">
								Travel Time Charge <img src="/img/Tips.png" @click="TipsBtn">
							</div>
							<div class="BookBoxLiBody">
								<div class="BodyIcon">
									<img src="/img/bookicon_07.jpg">
								</div>
								<h1>${{orderInfo.Order.TravelTimeCharge}}</h1>
								<!-- <h2>Travel Time Charge</h2> -->
							</div>
						</div>
					</div>
					<div class="BookTxtnew" style="margin-top: 20px;">
						<div class="FullBoxList">
							<label>Contact Detail</label>
							<div class="TxtParty p-left5">
								<div class="blockrow">
									<p>Name: {{orderInfo.Order.Contact}}</p>
									<p>Phone: {{orderInfo.Order.Phone}}</p>
									<p>Email: {{orderInfo.Order.Email}}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="BookPadding">
						<div class="FullLiBox" v-for="(locatitem,index) in orderInfo.Locations">
							<div class="FullBoxList">
								<label v-if="index==0">
									<!-- <span v-if="orderInfo.Locations.length>2">From</span>
									<span v-else>Pick-up</span> -->
									From
								</label>
								<label v-else-if="index==orderInfo.Locations.length-1">to</label>
								<label v-else>via</label>
								<div>
									<p>{{locatitem.Address}}</p>
								</div>
							</div>
							<div class="FullBoxList">
								<label>Type</label>
								<div>
									<p>{{locatitem.MoveType.text}}</p>
								</div>
							</div>
							<div class="FullBoxList" v-if="locatitem.PakingDistance">
								<label>Access</label>
								<div>
									<p>{{locatitem.Floor}}</p>
									<p>Truck parking within {{locatitem.PakingDistance}}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="BookTxt MarginBottom20" v-show="orderInfo.Items.length>0" style="padding-bottom: 20px;">
						<div class="FullBoxList">
							<label>Items</label>
							<div class="TxtParty">
								<div class="blockrow" v-for="(orderitem,index) in orderInfo.Items">
									<p>{{orderitem.ItemName}}</p>
									<p v-for="(orderitemsecond,index) in orderitem.Items">
										{{orderitemsecond.ItemName}}
										(<span v-for="(orderitemlast,index) in orderitemsecond.Items">
											{{orderitemlast.ItemName}}X{{orderitemlast.Qty}},
										</span>)
									</p>
								</div>
							</div>

						</div>
						<div class="MoreBookTxt" style="display: none;" v-if="orderInfo.Items.length>0">
							<a href="javascript:;">View All</a>
						</div>
					</div>
					<div class="BookTxtnew" v-if="orderInfo.Order.Remark">
						<div class="FullBoxList">
							<label>Comment</label>
							<div>
								<p>{{orderInfo.Order.Remark}}</p>
							</div>
						</div>
					</div>
					<div class="BookTxtnew" v-if="orderInfo.OrderCharge.AdditionTime||orderInfo.OrderCharge.AdditionAmount||orderInfo.OrderCharge.HeavyDescript||orderInfo.Extra.length>0">
						<div class="FullBoxList">
							<label>Extra</label>
							<div class="TxtParty">
								<div class="blockrow" v-if="orderInfo.OrderCharge.AdditionAmount">
									<p>Extra Amount: ${{orderInfo.OrderCharge.AdditionAmount}}</p>
								</div>
								<div class="blockrow" v-if="orderInfo.OrderCharge.AdditionTime">
									<p>Extra Time(hour): {{orderInfo.OrderCharge.AdditionTime}}</p>
								</div>
								<div class="blockrow"	v-if="orderInfo.OrderCharge.HeavyDescript">
									<p>Heavy Fee: {{orderInfo.OrderCharge.HeavyDescript}} ${{orderInfo.OrderCharge.HeavyFee}}</p>
								</div>
								<div class="blockrow" v-for="(orderitem,index) in orderInfo.Extra">
									<p>{{orderitem.Descript}}: ${{orderitem.Amount}}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="SucessBtnBox">
					<a href="/" class="calendar">
						Home Page
					</a>
				</div>
			</div>
		</div>
		<div class="TipsPop" v-show="Tipsstu"></div>
		<div class="TipsPopShow" v-show="Tipsstu">
			<div class="TipsPopHread">
				Travel Time Charge
				<a href="javascript:;" @click="closeTips"><img src="/img/closebtn.jpg"></a>
			</div>
			<div class="TipsPopBody">
				Travel time is a set amount of time we charge to get to and from your properties. These times do not change in the
				event of heavy traffic or unforeseen delays and are already included in the cost of this estimate.
			</div>
		</div>
		<homebottom />
	</div>
</template>

<script>
	import api from '@/api'
	import homebottom from '@/components/homebottom.vue'
	// @ is an alias to /src

	export default {
		name: 'sucess',
		data() {
			return {
				Tipsstu: false,
				orderInfo: {},
				submittype:2,
			}
		},
		components: {
			homebottom
		},
		created: function() {
			var that = this;
			this.$nextTick(function() {
				this.Info();
			})
		},
		mounted() {
			var that=this;
			if(that.$route.query.submittype){
				that.submittype=that.$route.query.submittype;
			}
		},
		methods: {
			Info() {
				var that = this;
				if (that.$route.query.orderid) {
					window.popUpFrame.Loading(api.getorderInfo(that.$route.query.orderid)).then(res => {
						if (res.data.code == 1000) {
							that.orderInfo = res.data.data;
						} else {}
					});
				} else {
					this.$router.push({
						path: '/',
					});
				}
			},
			TipsBtn() {
				this.Tipsstu = true;
			},
			closeTips() {
				this.Tipsstu = false;
			}
		}
	}
</script>
<style scoped="scoped" lang="less">
	.p-left5{
		padding-left: 5px;
	}
	.TipsPop {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: rgba(0, 0, 0, .5);
		z-index: 90;
	}

	.TipsPopShow {
		width: 500px;
		background: #fff;
		position: fixed;
		z-index: 91;
		left: 50%;
		border-radius: 8px;
		top: 50%;
		transform: translate(-50%, -50%);
		box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

		.TipsPopHread {
			border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 15px;
			font-size: 22px;
			font-weight: bold;
			width: 100%;
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
		}

		.TipsPopBody {
			padding: 20px;
			font-size: 18px;
			color: #000000;
			text-align: left;
		}
	}
	@media (max-width: 768px) {
		.TipsPopShow{
			width: 96%;
		}
	}
	.BookBoxLiHread img {
		width: 16px;
		margin-left: 2px;
		cursor: pointer;
	}
</style>
