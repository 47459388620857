<template>
  <div class="pr Fotter">
		<div class="container flex BetWeen">
			<div class="FotterLeft">
				<div class="Fotterbox">
					<img src="/img/imglogo.png">
					<h1>Office Hours: 9AM-5PM</h1>
				</div>	
			</div>
			<div class="FotterRight">
				<div class="FotterRightTop">
					<p>Phone:  (03) 8395 1214</p>
					<p>Email: info@imover.com.au</p>
					<p>Address: 1360 Dandenong Rd, Hughesdale VIC 3166</p>
					<p>instagram: ImoverRelocationGroup</p>
				</div>
				<div class="FotterRightBottom">
					© Copyright Imover Relocation Group. All Rights Reserved.
				</div>
			</div>
		</div>
  </div>
</template>

<script>

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
