<template>
	<div class="AboutTxt">
		<div class="container">
			<div class="FullPiceBox">
				<h1>
					Full quote
				</h1>
				<p>
					This is just a quick guide based on average moves like yours. To get a more accurate quote continue on for a full quote.
				</p>
				<div class="FullPiceNum">
					<img src="/img/fullimg_03.jpg">
					<div class="FullPiceTxt">
						<h1>${{dataval.minAmount | tofixednew}}-${{dataval.maxAmount | tofixednew}}</h1>
						<p>{{dataval.minHour | tofixed}} to {{dataval.maxHour | tofixed}} hours</p>
						<!-- <p>{{dataval.mover}} mover & {{dataval.vechileNumber}} {{dataval.vechileModel}} truck</p> -->
						<p>{{dataval.mover}} {{dataval.mover>1?'movers':'mover'}} & {{dataval.vechileModel}}</p>
					</div>
				</div>
				<div class="FullEdit">					
					<div class="FullBtnBox">
						<a href="javascript:;" class="calendar" @click="fullbtn()">
							<span class="icon"></span><span>Make a booking</span>
						</a>
						<a href="javascript:;" class="email" @click="fullemail()">
							<span class="icon"></span><span>Email me the quote</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import api from '@/api'
	import popUpFrame from '@/components/popUpFrame.vue'
	export default {
		name: 'getfullpice',
		components: {
		},
		data() {
			return {
				dataval:{},
				fullquote:0
			}
		},
		filters:{
			tofixed:function (value) {
				return Number(value).toFixed(1);
			},
			tofixednew:function (value) {
				return Number(value).toFixed(0);
			}
		},
		methods: {
			async init(){
				this.dataval=await this.utils.getPice(2,0);
			},
			fullbtn(){
				this.$store.dispatch('getsubmitTypeFun',2);
				this.$router.push({path:'/fullprice',params:{}});
//				this.utils.Getsubmit();
			},
			fullemail(){
				this.$store.dispatch('getsubmitTypeFun',3);
				this.utils.Getsubmit('email');
			}
		},
		created() {},
		mounted() {
//			console.log(this.$store.state)
			this.fullquote=this.$route.query.fullquote?this.$route.query.fullquote:0;
			this.$nextTick(function(){
				this.init();
			})
			this.$store.dispatch('setisshowFun', false);
		},
	}
</script>

<style>

</style>