<template>
	<div v-bind:class="'popUpFrame '+(isOpen?'active':'')">
    	<div class="bg"></div>
	    <div class="loading" v-if="popType==2">
          <img src="/img/car.gif">
          <h3>Loading...</h3>
	    </div>
  	</div>
</template>

<script>
	import api from '@/api';
	export default {
	  name: 'pop-up',
	  data(){
	    return {
	      isOpen:false,
	      popType:2,
	      callback:()=>{},
	      DailogFlag:false,
	    }
	  },
	  methods:{
	    Loading(promise){
	      this.popType = 2;
	      setTimeout(()=>{
	        if(!this.DailogFlag){
	          this.isOpen = true;
	        }
	      },0)
	      return promise.then(res=>{
		    setTimeout(()=>{
		        if(!this.DailogFlag){
		          this.isOpen = false;
		        }
		    },0)
	        return res;
	      })
	    },
	    Close(){
	      if(this.popType==1){
	        this.isOpen = false;
	      }
	    },
	  }
	}
</script>

<style>
	.popUpFrame{
		display:none;
	}
	.popUpFrame.active{
		display: block;
	}
.bg{
	background: rgba(33,33,33,.4);
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 98;
}
.loading{
	width:280px;
	height:271px;
	background:rgba(255,255,255,1);
	border-radius:11px;
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -135px;
	margin-left: -140px;
	z-index: 99;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
}
.loading img{
	width: 165px;
	height: 165px;
}
.loading h3{
	color: #333;
	font-size: 18px;
	line-height:25px;
}
</style>