<template>
	<div class="GetQuote">
		<processhread @speed="getblack" v-if="$store.state.hread==1" :speed="$store.state.Layout" :numsize="$store.state.numsize" />
		<processmorehread @speed="getblack" :SelHouse="$store.state.processdata.SelHouse" v-if="$store.state.hread==2" :speed="$store.state.Layout" :numsize="$store.state.numsize" />
		<div class="container moblieshow" v-if="$store.state.Layout<7">
			<!--手机端顶部动画-->
			<processbottom :DefaultState="$store.state.DefaultAnimationState" :AnimationList="$store.state.getlist" :houseType="$store.state.processdata.GetHouseType" v-if="$store.state.Layout>1&&$store.state.Layout!=4&&$store.state.Layout!=6" />
		</div>
		<transition mode="out-in">
			<router-view></router-view>
		</transition>
		<progressicon v-show="$store.state.progressicon"></progressicon>
		<div class="container pcshow" v-if="$store.state.isshowbottom">
			<!--底部动画-->
			<processbottom :DefaultState="$store.state.DefaultAnimationState" :AnimationList="$store.state.getlist" :houseType="$store.state.processdata.GetHouseType" />
		</div>
		<!--<processedit :editlist="$store.state.getlist" :parkingindex="$store.state.parkingindex" v-if="(6<$store.state.Layout&&$store.state.Layout<11)||$store.state.Layout==14" />-->
		<!--第五步下一步-->
		<div class="AddressSubmit" v-if="$store.state.Layout==5">
			<div class="daybox" v-if="$store.state.Timedata.pickedDate">
				<a href="javascript:;" :class="morntxt=='Morning'?'active':''" @click="Mornbtn('Morning')">
					Morning
				</a>
				<a href="javascript:;" :class="morntxt=='Afternoon'?'active':''" @click="Mornbtn('Afternoon')">
					Afternoon
				</a>
				<a href="javascript:;" :class="morntxt=='Anytime'?'active':''" @click="Mornbtn('Anytime')">
					Anytime
				</a>
			</div>
			<!--<a href="javascript:;" @click="DateNext()" class="showdata">Next</a>-->
		</div>
		<div class="ShowPadd"></div>
		<!--<a href="javascript:;" @click.prevent="Layout++">233232</a>-->
		<homebottom />
	</div>
</template>

<script>
	// @ is an alias to /src
	import processhread from '@/components/processhread.vue'
	import processbottom from '@/components/processbottom.vue'
	import processmorehread from '@/components/processmorehread.vue'
	import homebottom from '@/components/homebottom.vue'
	import progressicon from '@/components/progressicon.vue'
	//添加地址
	import processedit from '@/components/processedit.vue'
	export default {
		name: 'GetQuote',
		components: {
			processbottom,
			processhread,
			processmorehread,
			homebottom,
			processedit,
			progressicon,
		},
		data() {
			return {
				Layout: 1,
				hread: 1,
				processdata: {
					SelHouse: '',
					GetHouseType: '',
				},
				fromType: 'House',
				fromhouseType: 'House',
				fromhouse: '',
				tohouse: '',
				fromfloor: '1',
				tofloor: '1',
				hreadnum: 1,
				numsize: 0,
				parkingindex: 0,
				morntxt:'',
			}
		},
		methods: {
			Mornbtn(txt){
				this.morntxt=txt;
				this.$store.dispatch('getOnlyDateMethodFun', this.morntxt);
				this.$store.dispatch('getLayoutFun', 6);
				this.$store.dispatch('setisshowFun', false);
				this.$router.push({
					path: '/getquote/processfullpice',
					params: {}
				});
			},
			//回退
			getblack(data) {
				this.$store.dispatch('getLayoutFun', parseInt(data));
				if(this.$store.state.Layout == 7) {
					this.$store.dispatch('getparkingindexFun', 0);
				}
			},
			_isMobile() {
				let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
				return flag;
			},
			Layoutbtn(name) {
				console.log(name);
				let that = this;
				switch(name) {
					case 'processfrom':
						that.Layout = 2;
						break;
				}
			},
		},
		mounted() {
//			console.log(this.GLOBAL.isshowbottom);
			if(this.$store.state.Layout==1){
				this.$router.push({ 
						path: '/getquote/processfist'
				});
			}
			if(this.$store.state.Timedata.onlytime){
				this.morntxt=this.$store.state.Timedata.onlytime;
			}else{
				this.morntxt='';
			}
		},
		created() {
			var SelhouseNew = this.$route.query.SelHouse;
			this.processdata.GetHouseType = this.$route.query.GetHouseType;
			this.processdata.SelHouse = SelhouseNew;
			if(SelhouseNew == 'home') {
				this.Layout = 2;
			} else if(SelhouseNew == 'business') {
				this.Layout = 4;
				this.getlist[0].housetype = 4;
				this.getlist[0].house = 'Business';
				this.getlist[1].housetype = 4;
				this.getlist[1].house = 'Business';
			} else {
				this.Layout = 1;
			}
		},
		watch: {
			"$route.name": function(name) {
				switch(name) {
					case 'processdate':
						if(this.$store.state.Timedata.onlytime){
							this.morntxt=this.$store.state.Timedata.onlytime;
						}else{
							this.morntxt='';
						}
						this.$store.dispatch('getLayoutFun', 5);
						this.$store.dispatch('setisshowFun',true);
						break;
				}
			}
		}
	}
</script>
<style>
	.v-enter {
		opacity: 0;
		transform: translateX(150px);
	}
	
	.v-leave-to {
		opacity: 0;
		transform: translateX(-150px);
	}
	
	.v-enter-active,
	.v-leave-active {
		transition: all 0.5s ease;
	}
</style>