<script>
//const isshowbottom=false;
  export default
  {
  	isshowbottom:false,//控制动画部分显示
		hreadnum:1,//头部流程展示
  	processdata:{
			SelHouse: '',
			GetHouseType: '',
		},		
		Layout: 1,
  	getlist:[
			{
				house:'House',
				floor:'0',
				name: "Suburb or address", 
				inputtext: "", 
				id: 0,
				rice:"",
				docklands:false,
			},
			{
				house:'',
				floor:'0',
				name: "Suburb or address", 
				inputtext: "", 
				id: 1,
				rice:"",
				docklands:false,
			},
		],//提交数据
		processdata:{
			SelHouse: '',
			GetHouseType: '',
		},//第一步选择的房子还是商业，类型
  	setisshow(isshow){
  		this.isshowbottom=isshow;
  	},
  	getMsgFormSon(data) {
			this.processdata.SelHouse=data.SelHouse;
			this.processdata.GetHouseType=data.GetHouseType;
			var SelhouseNew = data.SelHouse;
			if(SelhouseNew == 'home') {
				this.Layout = 2;
			} else if(SelhouseNew == 'business') {
				this.Layout = 4;
				this.getlist[0].housetype = 4;
				this.getlist[0].house = 'Business';
				this.getlist[1].housetype = 4;
				this.getlist[1].house = 'Business';
			}
			this.hreadnum=2;
		},
		getMsgFormfrom(data) {				
			this.Layout = 3;				
			if(data=="Apartment"){
				this.getlist[0].floor='0';
			}
			var housertype;
			switch(data){
  				case 'House':
  				  housertype = 1;
  				  break;
  				case 'Apartment':
  				  housertype = 2;
  				  break;
  				case 'facility':
  				  housertype = 3;
  				  break;
  			}
			this.getlist[0].housetype = housertype;
			this.getlist[0].house = data;
		}
  }
</script>