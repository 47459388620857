import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import GetQuote from '../views/GetQuote.vue'
import contactus from '../views/ContactUs.vue'
import fullprice from '../views/FullPrice.vue'
import quickquote from '../views/Quickquote.vue'
import bookingsucess from '../views/BookingSucess.vue'
import bookingemailsucess from '../views/BookingEmailSucess.vue'

import termstndconditions from '../views/TermsAndConditions.vue'
import datenew from '../views/datenew.vue'
import processfist from '../components/processfist.vue'
import processfrom from '../components/processfrom.vue'
import processto from '@/components/processto.vue'
import processaddress from '@/components/processaddress.vue'
import processdate from '@/components/processdate.vue'
import processfullpice from '@/components/processfullpice.vue'
import processfullpicelast from '@/components/processfullpicelast.vue'
import processparking from '@/components/processparking.vue'
import processhousefloor from '@/components/processhousefloor.vue'
import processdocklands from '@/components/processdocklands.vue'
import processitems from '@/components/processitems.vue'
import processbusinessitems from '@/components/processbusinessitems.vue'
import processcontact from '@/components/processcontact.vue'
import chat from '../views/Chat.vue'
import eamilquote from '../views/Eamilquote.vue'
import bookquote from '../views/Bookquote.vue'
import code from '../views/code.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import(/* webpackChunkName: "about" */ '../views/blog.vue')
  },  
  {
    path: '/blog/:id',
    name: 'bloginfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/bloginfo.vue')
  }, 
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },  
  {
    path: '/getquote',
    name: 'getquote',
    component: GetQuote,
    children: [
        { path: "/getquote/processfist", component: processfist, name: 'processfist',},
        { path: "/getquote/processfrom", component: processfrom, name: 'processfrom',},
        { path: "/getquote/processto", component: processto, name: 'processto',},
        { path: "/getquote/processaddress", component: processaddress, name: 'processaddress',},
        { path: "/getquote/processdate", component: processdate, name: 'processdate',},
        { path: "/getquote/processfullpice", component: processfullpice, name: 'processfullpice',},
        { path: "/getquote/processparking", component: processparking, name: 'processparking',},
        { path: "/getquote/processhousefloor", component: processhousefloor, name: 'processhousefloor',},
        { path: "/getquote/processdocklands", component: processdocklands, name: 'processdocklands',},
        { path: "/getquote/processitems", component: processitems, name: 'processitems',},
        { path: "/getquote/processbusinessitems", component: processbusinessitems, name: 'processbusinessitems',},
        { path: "/getquote/processcontact", component: processcontact, name: 'processcontact',},
        { path: "/getquote/processfullpicelast", component: processfullpicelast, name: 'processfullpicelast',}
    ]
  },  
  {
    path: '/datenew',
    name: 'datenew',
    component: datenew
  }, 
  {
    path: '/termstndconditions',
    name: 'termstndconditions',
    component: termstndconditions
  },  
  {
    path: '/quickquote',
    name: 'quickquote',
    component: quickquote
  },
  {
    path: '/bookingsucess',
    name: 'bookingsucess',
    component: bookingsucess
  },
  {
    path: '/bookingemailsucess',
    name: 'bookingemailsucess',
    component: bookingemailsucess
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: contactus
  },
  {
    path: '/fullprice',
    name: 'fullprice',
    component: fullprice
  },
  {
    path: '/chat',
    name: 'chat',
    component: chat
  },
  {
    path: '/eamilquote',
    name: 'eamilquote',
    component: eamilquote
  },
  {
    path: '/bookquote',
    name: 'bookquote',
    component: bookquote
  },
  {
    path: '/qrcodes/index/:id',
    name: 'code',
    component: code
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push
  VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
