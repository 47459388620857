<template>
	<div class="ProcessBody">
		<div class="HomeBody PaddSize">
	  		<div class="container">
	  			<h1 class="ProcessLittle">
					I need to
				</h1>
	  			<!--<h1 class="LittleTxt">
	  				Get a quick estimate
	  			</h1>-->
				<div class="ProcessImgBox" :style="{height: heightnum + 'px'}">
					<img src="/img/cactus.png" class="cactus" :class="Layoutenew=='A1'?'active':''" v-if="">
					<img src="/img/stool.png" class="stool" :class="Layoutenew=='A1'?'active':''">
					<img src="/img/smallment.png" class="smallment" :class="Layoutenew=='A2'?'active':''">
					<img src="/img/smallhouse.png" class="smallment" :class="Layoutenew=='A3'?'active':''">
					<img src="/img/newhouse.png" class="smallment" :class="Layoutenew=='A4'?'active':''">
					<img src="/img/businessSmall.png" class="smallment" :class="Layoutenew=='B1'?'active':''">
					<img src="/img/businessMedium.png" class="smallment" :class="Layoutenew=='B2'?'active':''">
					<img src="/img/businessBigtall.png" class="smallment" :class="Layoutenew=='B3'?'active':''">
					<img src="/img/businessLarge.png" class="smallment" :class="Layoutenew=='B4'?'active':''">
					<img src="/img/businessLots.png" class="smallment" :class="Layoutenew=='B5'?'active':''">
					<img src="/img/men.png" class="menIcon" :class="Layoutenew=='A2'?'active':''">
					<img src="/img/men.png" class="menIcon one active">
					<img src="/img/men.png" class="menIcon two" :class="Layoutenew!='A2'?'active':''">
					<img src="/img/men.png" class="menIcon three" :class="Layoutenew=='A4'||Layoutenew=='B5'?'active':''">
					<img src="/img/bigcar.png" class="car" :style="{marginLeft: newleft + 'px',width:newleft-60+'px'}">
				</div>
	  			<div class="TypeSel">
	  				<a href="javascript:;" @click="TypeSelHread('home')" data-txt="home"><span class="check" :class="SelHouse=='home'?'active':''"></span>Move a home</a>
	  				<a href="javascript:;" @click="TypeSelHread('office')" data-txt="office"><span class="check" :class="SelHouse=='office'?'active':''"></span>Move an office</a>
	  			</div>
	  			<div class="SelTypeTab">
					<transition mode="out-in">
			  			<househome @func="getHomeType" v-if="SelHouse=='home'"/> 
			  			<housebusiness @func="getBusinessType" v-if="SelHouse=='office'"/> 
			  		</transition>	
	  			</div> 
	  			<div class="TypeBottomTxt fadeInUp animated" v-if="$store.state.processdata.GetItem">
	  				Estimate: ${{$store.state.processdata.GetItem.HourlyPrice}}/h
	  			</div>
	  			<div class="TypeBottomLabel fadeInUp animated" v-if="$store.state.processdata.GetItem">
	  				<a href="javascript:;">{{$store.state.processdata.GetItem.Mover}} movers</a>
	  				<!-- <a href="javascript:;">{{$store.state.processdata.GetItem.vechileNumber}} {{$store.state.processdata.GetItem.VechileModel}} truck</a> -->
	  				<a href="javascript:;">{{$store.state.processdata.GetItem.VechileModel}}</a>
	  				<a href="javascript:;">{{$store.state.processdata.GetItem.MinHour}}-{{$store.state.processdata.GetItem.MaxHour}} hours</a>
	  			</div>
			    <a href="javascript:;" class="BottomBtn" @click="GetSubime" v-if="$store.state.processdata.GetItem">
				  	<img src="/img/invoice.png">Get a quick estimate
				</a>
	    	</div>
	  	</div>
	</div>
</template>

<script>
import househome from '@/components/househome.vue'
import housebusiness from '@/components/housebusiness.vue'
export default {
  name: 'processfist',
  props: {
    msg: String
  },
  components: {
    househome,
    housebusiness
  },
  data() {
	return {
		Layoutenew:this.$store.state.processdata.GetItem?this.$store.state.processdata.GetItem.Code:'A1', 
		newleft:150,
		newtype:true,
		SelHouse:'home',
		BusinessLayout:1,
		heightnum:100,
		deg:1.1,
	}
  },
  mounted(){
  	this.$store.dispatch('setisshowFun',false);
  	if(!this.$route.params.blackstatus){
		this.$store.commit('fullItemEdit',false);
		this.$store.dispatch('progressiconFun',true);
		this.$store.dispatch('getOnlyDateMethodFun', '');
		this.$store.dispatch('getOrderIdFun',0);
		this.$store.dispatch('getBusinessitemFun',{});
		this.$store.dispatch('getitemFun',{});
		this.$store.dispatch('getContactFun',{});
		this.$store.dispatch('getLayoutFun',1);
		this.$store.dispatch('getContinueFun',0);
		this.$store.dispatch('getHreadFun',1);
  		this.$store.dispatch('getlistoperaFun',[]);
		this.$store.dispatch('getsubimeStateFun',false);
  	}	
  },
  methods: {
  	getHomeType(e){
  		this.Layoutenew=e.Code;
  		this.GetItem=e;
  		if(e=='A1'){
  			this.newleft=10+150;
  			this.heightnum=70;
  		}
  		else if(e=='A2'){
  			this.newleft=20+150;
  			this.heightnum=180;
  		}
  		else if(e=='A3'){
  			this.newleft=30+150;
  			this.heightnum=120;
  		}else{
  			this.newleft=40+150;
  			this.heightnum=200;
  		}
  		var sel={
  			SelHouse:this.SelHouse,
  			GetHouseType:this.Layoutenew,
  			GetItem:this.GetItem,
  		}
		this.$store.dispatch('getMsgFormSonFun',sel);
  	},
  	getBusinessType(e){
  		this.Layoutenew=e.Code;
  		this.GetItem=e;
  		if(e=='B1'){
  			this.newleft=10+150;
  			this.heightnum=200;
  		}
  		else if(e=='B2'){
  			this.newleft=20+150;
  			this.heightnum=200;
  		}
  		else if(e=='B3'){  			
  			this.newleft=30+150;
  			this.heightnum=280;
  		}else if(e=='B4'){
  			this.newleft=35+150;
  			this.heightnum=250;
  		}else if(e=='B5'){
  			this.newleft=40+150;
  			this.heightnum=250;
  		}
  		var sel={
  			SelHouse:this.SelHouse,
  			GetHouseType:this.Layoutenew,
  			GetItem:this.GetItem,
  		}
		this.$store.dispatch('getMsgFormSonFun',sel);
  	},
  	TypeSelHread(e){
  		this.SelHouse=e;
  		if(this.SelHouse=='home'){
  			this.Layoutenew='A1';
  			this.heightnum=70;
  		}else{
  			this.Layoutenew='B1';
  			this.heightnum=200;
  		}
  	},
  	GetSubime(){
  		var sel={
  			SelHouse:this.SelHouse,
  			GetHouseType:this.Layoutenew,
  			GetItem:this.GetItem?this.GetItem:this.$store.state.processdata.GetItem,
  		}
		this.$store.dispatch('getMsgFormSonFun',sel);
		this.$store.dispatch('setisshowFun',true);
  		this.$router.push({path:'/getquote/processfrom',params:{sel:sel}})
  	},
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.v-enter {
  opacity: 0;
  transform: translateX(150px);
}
.v-leave-to{
	opacity: 0;
  	transform: translateX(-150px);
}
.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}
</style>
