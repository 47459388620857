<template>
	<div class="ProcessBody">
		<!-- <remote-js :src="'https://maps.googleapis.com/maps/api/js?key='+$store.state.PriceSettings.GoogleApiKey+'&libraries=places&callback='+initMap" async defer></remote-js> -->
		<div class="HomeBody NewPadd">
	  		<div class="container">
	  			<h1 class="ProcessLittle">
	  				I need to move from
				</h1>
				<transition-group name="list-complete" tag="div">
					<div class="ContentBox list-complete-item" v-for="(item,index) in getlist" :key="item.id">
						<div class="ContentDress">
							<span>{{index+1}}</span> 
							<div class="ContentDressTxt">
								<span v-if="index==0">from</span>
								<span v-else-if="index==(getlist.length-1)">to</span>
								<span v-else>
									<span v-if="index==1">via</span>
									<span v-else>and</span>
								</span>							
								<div class="Dress" v-if="item.house"> 
									a
									<span @click="HouseEdit(index)" v-if="item.house!='office'">{{item.house}}</span> 
									<span class="bigcapit" v-else>{{item.house}}</span> 
									in 
									<span v-if="item.inputtext!=''" @click="DressEdit(index)">{{item.inputtext}}</span>
								</div>
							</div>	
						</div>
						<div  v-show="item.exitstate">
							<!--选择类型-->
							<div class="ProcessSecondBox" v-show="item.getIndex==1" key="box1">
								<a href="javascript:;" data-txt="Apartment" @click="ProcessBtn('Apartment',index)" >
									Apartment
								</a>
								<a href="javascript:;" data-txt="House" @click="ProcessBtn('House',index)" >
									House
								</a>
								<a href="javascript:;" data-txt="facility" @click="ProcessBtn('facility',index)">
									Storage Facility
								</a>
							</div>
							<!--填写地址-->
							<div class="ContentInput" v-show="item.getIndex==2" key="box2">
								
								<input type="text" placeholder="Suburb or address" :id="'address'+index"  @focus="overhidden" @blur="overauto" v-model="item.inputtextall" v-on:input="inputshow(index)">
								<span class="Inputsubime" v-if="!item.subimebtn"></span>
								<a href="javascript:;" class="Inputsubime active" @click="Inputdress(index)" v-else></a>
							</div>
							<!--中间个数选择位置-->
							<div class="ChangePlaces" v-show="item.getIndex==3" key="box3">
								<h1>Will be stopping at this place</h1>
								<div class="PlacesBox">
									<span v-for="n in (getlist.length-1)" :key="n" @click="switchPosition(n,index)">{{n+1}}</span>
								</div>	
							</div>	
						</div>
					</div>
				</transition-group>	
	    		<div class="addTxt" v-if="subimeState">
					<a href="javascript:;" class="redtxt" @click="addList" v-if="getlist.length<5">+ Add more address</a>
					<a href="javascript:;" class="subimebtn" @click="Next()">Next</a>
				</div>
	    		<div class="addTxt" v-if="StopAddress">
					<a href="javascript:;" class="subimebtn" @click="StopBtn">Stop Adding Address</a>
				</div>
	  		</div>
	  	</div>
	</div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/velocity/1.2.3/velocity.min.js"></script>
<script>
export default {
  name: 'ProcessFrom',
  props: {
    msg: String,
    fromhouse: String,
  },
  components: {
    'remote-js': {
      render(createElement) {
        return createElement('script', {attrs: {type: 'text/javascript', src: this.src}});
      },
      props: {
        src: { type: String, required: true}
      }
    }
  },
  data() {
		return {
			Layout: 1, 
			hread:1,
			comName:'processto',
			UpdataState:false,//修改还是新增判断
			subimeState:false,//判断提交按钮是否展示
			StopAddress:false,//判断停止按钮是否展示
			listid:0,
			getlist:[],
			initMap:'',
			upindex:-1,
			upgetIndex:-1,
		}
	},
	methods:{
		overauto(){
			var mo=function(e){
	　　　　　　e.preventDefault();
	　　　　 };
			document.body.style.overflow='';
        	document.removeEventListener("touchmove",mo,false);
		},
		overhidden(){
			var mo=function(e){
	　　　　　　e.preventDefault();
	　　　　 };
			document.body.style.overflow='hidden';
        	document.addEventListener("touchmove",mo,false);
		},
		loadThirdAssets(src) {
			var that=this;
			if([].slice.call(document.querySelectorAll('third')).length >= 2) {
				return;
			}
			const element = document.createElement('script');
			element.setAttribute('src', src);
			element.className = 'third';
			element.setAttribute('async', 'async');
			document.getElementsByTagName('body')[0].appendChild(element);
			element.onload = () => {
				if(that.edit){
					for(var i=0;i<that.getlist.length;i++){
						that.GetLocationAddress(i);
					}
				}else{
					for(var i=0;i<that.getlist.length;i++){
						that.GetLocationAddress(i);
					}
				}
			};
		},
		Next(){
			var that=this;
			this.$store.dispatch('getLayoutFun',5);
			if(this.edit=="update"){
				this.$router.push({path:'/fullprice',query: {					
					'quotetype':that.quotetype,
				}});
			}else{
				this.$router.push({path:'/getquote/processdate',params:{}});
			}
		},
		StopBtn(){//填写中间信息时删除按键
			var that=this;
			var originalIndex=that.OriginalIndex();
			that.subimeState=true;
			that.StopAddress=false;
			that.$store.dispatch("getsubimeStateFun",that.subimeState);
			that.$store.dispatch("getStopAddressFun",that.StopAddress);
			that.getlist.splice(originalIndex,1);
		},
		OriginalIndex(){//查看现在编辑那条信息
			var that=this;
			for(var i=0;i<that.getlist.length;i++){
				if(that.getlist[i].getIndex!=0){
					return i
				}
			}
		},
		async ProcessBtn(fromtype,index){//定义房屋类型
			var that=this;	
			console.log(that.getlist);
			var housertype;
			switch(fromtype){
  				case 'House':
  				  housertype = 1;
  				  break;
  				case 'Apartment':
  				  housertype = 2;
  				  break;
  				case 'facility':
				  fromtype='Storage Facility';
  				  housertype = 3;
  				  break;
  			}
			that.getlist[index].housetype = housertype;
			that.getlist[index].house=fromtype;
			that.$store.dispatch("getMsgAddressFun",that.getlist);
			if(that.UpdataState){
				that.getlist[index].getIndex=0;
				that.UpdataState=false;
				var originalIndex=await that.OriginalIndex();
				if(that.upindex>-1){
					that.getlist[that.upindex].exitstate=true;
					that.getlist[that.upindex].getIndex=that.upgetIndex;
					return;
				}
				if(originalIndex){
					that.getlist[originalIndex].exitstate=true;//切换回之前新增
				}
			}else{
				that.getlist[index].getIndex=2;
			}
			that.$forceUpdate();
		},
		inputshow(index){//输入后可以提交
			var that=this;		
			// if(index!=indexnum){
			// 	that.GetLocationAddress(index);	
			// }
			var indexnum=index;
			if(that.getlist[index].inputtextall.length>0){//判断文本框文字
				that.getlist[index].subimebtn=false;
			}else{
				that.getlist[index].subimebtn=false;
			}
			that.$forceUpdate();
		},
		Inputdress(index){//输入地址
			var that=this;
			var getlistli={//添加的初始值
				house:'',
				floor:'0',
				name: "Suburb or address", 
				inputtext: "", 
				inputtextall: "", 
				id: 0,
				writing:'from',
				rice:"",
				docklands:null,
				getIndex:1,
				exitstate:true,
				subimebtn:false,				
				docklandsstate:false,
			};
			if(that.getlist[0].house=="office"){
				getlistli.house = 'office';
				getlistli.getIndex = 2;
				getlistli.housetype=4;
			}		
			getlistli.id=index+1;
			if(that.UpdataState){//判断是否是修改状态
				that.getlist[index].getIndex=0;
				this.UpdataState=false;
				var originalIndex=that.OriginalIndex();
				that.getlist[originalIndex].exitstate=true;//切换回之前新增
			}else{
				if(index==0&&that.getlist.length<2){
					that.getlist[index].getIndex=0;
					getlistli.writing='to';
					that.listid=that.listid+1;
					getlistli.id=that.listid;	
					that.getlist.push(getlistli);
					that.$nextTick(() => {
						that.GetLocationAddress(1);
					})
				}else{
					if(that.getlist.length<6&&that.getlist.length>2){
						that.getlist[index].getIndex=3;
					}else{
						that.getlist[index].getIndex=0;
						that.subimeState=true;
						that.StopAddress=false;
						that.$store.dispatch("getsubimeStateFun",that.subimeState);
						that.$store.dispatch("getStopAddressFun",that.StopAddress);
					}
				}
			}
			that.$store.dispatch("getMsgAddressFun",that.getlist);
		},
		switchPosition(num,index){//切换位置
			var that=this;
			that.getlist[index].getIndex=0;
			that.getlist[num] = that.getlist.splice(index, 1, that.getlist[num])[0];
			that.subimeState=true;
			that.StopAddress=false;
			that.$store.dispatch("getsubimeStateFun",that.subimeState);
			that.$store.dispatch("getStopAddressFun",that.StopAddress);
			that.$store.dispatch("getMsgAddressFun",that.getlist);
		},
		addList(){//添加中间地址
			var that=this;	
			var getlistli={
				house:'',
				floor:'0',
				name: "Suburb or address", 
				inputtext: "", 
				id: 0,
				writing:'from',
				rice:"",
				docklands:null,
				getIndex:1,
				exitstate:true,
				subimebtn:false,						
				docklandsstate:false,
			};//添加addlist
			getlistli.id=that.listid+1;
			that.listid=that.listid+1;
			that.getlist.splice(that.getlist.length-1,0,getlistli);
			that.subimeState=false;
			that.StopAddress=true;		
			that.$nextTick(() => {
				for(var i=1;i<that.getlist.length;i++){
					that.GetLocationAddress(i);
				}
			})
			that.$store.dispatch("getsubimeStateFun",that.subimeState);
			that.$store.dispatch("getStopAddressFun",that.StopAddress);	
		},
		async HouseEdit(index){//修改房屋类型
			for(var i=0;i<this.getlist.length;i++){
				this.getlist[i].exitstate=false;
			}
			var originalIndex=await this.OriginalIndex();
			if(index==originalIndex){
				this.upindex=originalIndex;
				this.upgetIndex=this.getlist[index].getIndex;
			}else{
				this.upindex=-1;
			}
			this.UpdataState=true;
			this.getlist[index].getIndex=1;
			this.getlist[index].exitstate=true;
		},
		DressEdit(index){//修改地址类型
			for(var i=0;i<this.getlist.length;i++){
				this.getlist[i].exitstate=false;
			}
			this.UpdataState=true;
			this.getlist[index].getIndex=2;
			this.getlist[index].exitstate=true;
		},
		GetLocationAddress(index) { //调用google搜索
            var inputId = 'address';
            var _self = this;
            var searchOption = {
                componentRestrictions: { country: "AU" },
                //bounds: new window.google.maps.LatLngBounds(
                //    new window.google.maps.LatLng('-39.264537', '140.988653'),
                //    new window.google.maps.LatLng('-33.959714', '150.480840')
                //),
                types: ['geocode']
            }
            var input = document.getElementById(inputId+index);
            var autocomplete = new google.maps.places.Autocomplete(input, searchOption);
            var geocoder = new google.maps.Geocoder;
            autocomplete.addListener('place_changed', function () {
                var place = autocomplete.getPlace();
                if (!place.geometry) {
                    // User entered the name of a Place that was not suggested and
                    // pressed the Enter key, or the Place Details request failed.
                    window.alert("No details available for input: '" + place.name + "'");
                    return;
                }
                const re = new RegExp('(\\d+)/(\\d+).*Australia')
                const match = re.exec(place.name)
                let subpremise = null
                let streetNumber = null
                if (match) {
                    // We have a subpremise, save them to override the address returned later
                    subpremise = match[1]
                    streetNumber = match[2]
                }
                var placeId = place.place_id;
                geocoder.geocode({ 'placeId': place.place_id }, (results, status) => {
                    if (status !== 'OK') {
                        // window.alert('Geocoder failed due to: ' + status);
                        return;
                    }
                    place = results[0]
                    let addressComponents = {
                        subpremise: 'long_name',
						street_number: 'long_name',
						route: 'long_name',
						locality: 'long_name',
						administrative_area_level_1: 'short_name',
						administrative_area_level_2: 'long_name',
						administrative_area_level_3: 'long_name',
						country: 'long_name',
						postal_code: 'short_name',
						colloquial_area: 'long_name',
						neighborhood: 'long_name'
                    }
                    let returnData = {}
                    if (place.address_components !== undefined) {
                        // Get each component of the address from the place details
                        for (let i = 0; i < place.address_components.length; i++) {
                            let addressType = place.address_components[i].types[0]

                            if (addressComponents[addressType]) {
                                let val = place.address_components[i][addressComponents[addressType]]
                                returnData[addressType] = val
                            }
                        }
                        // Always override the subpremise, if we didn't match the regex we don't
                        // want one even if the address has one
                        returnData['subpremise'] = subpremise
                        if (match) {
                            // Override the street number with ours if regex matched because the
                            // address returned by geocoder might be wrong
                            returnData['street_number'] = streetNumber
                        }
                        returnData['place_id'] = place.place_id
                        returnData['latitude'] = place.geometry.location.lat()
                        returnData['longitude'] = place.geometry.location.lng()
                        returnData['types'] = place.types
                        // Use our address format
                        returnData['formatted_address'] = _self.addressFormat(returnData)
                        returnData['formatted_addressall'] = _self.addressFormatall(returnData)
						_self.getlist[index].subimebtn=true;
                        _self.CallBackData(inputId, returnData,index);
                    }
                })
            });
        },
        CallBackData(inputId, returnData,index) {
            var _self = this;
            if (returnData != null) {
                _self.getlist[index].newlocation = { PlaceId: '', Location: '', Address: '', Lat: 0, Lng: 0 };
                _self.getlist[index].inputtext = returnData.formatted_address;
                _self.getlist[index].inputtextall = returnData.formatted_addressall;
                _self.getlist[index].newlocation.Location = returnData.locality;
                _self.getlist[index].newlocation.PlaceId = returnData.place_id;
                _self.getlist[index].newlocation.Lat = returnData.latitude;
                _self.getlist[index].newlocation.Lng = returnData.longitude;                
                //document.getElementById(inputId).value = returnData["formatted_address"];
            }
        },
        addressFormat(location) {
           var format = ''
           if (location.subpremise) {
           	format += location.subpremise + '/'
           }
           if (location.street_number && location.route) {
           	format = format + location.street_number + ' ' + location.route + ', '
           } else if (location.route) {
           	format = format + location.route + ', '
           }
           format = format + (location.locality || location.colloquial_area || location.administrative_area_level_2 || location.neighborhood || location.postal_code || '') + ', ' + location.administrative_area_level_1 + ' '
           return format
        },
        addressFormatall(location) {
            var format = ''
			if (location.subpremise) {
				format += location.subpremise + '/'
			}
			if (location.street_number && location.route) {
				format = format + location.street_number + ' ' + location.route + ', '
			} else if (location.route) {
				format = format + location.route + ', '
			}
			format = format + (location.locality || location.colloquial_area || location.administrative_area_level_2 || location.neighborhood || location.postal_code || '') + ', ' + location.administrative_area_level_1 + ' ' + (location.postal_code || location.country || '')
			return format
        }

	},
	mounted() {
		this.getlist=this.$store.state.getlist;
		this.subimeState=this.$store.state.subimeState;
		this.StopAddress=this.$store.state.StopAddress;
		this.edit=this.$route.query.Edit?this.$route.query.Edit:false;
		this.quotetype=this.$route.query.quotetype?this.$route.query.quotetype:-1;
		this.loadThirdAssets('https://maps.googleapis.com/maps/api/js?key='+this.$store.state.PriceSettings.GoogleApiKey+'&libraries=places&language=en&callback=');
	},
	watch:{
	  	"getlist":{
	  		handler(newName, oldNam){
				var that=this;
				for(var i=0;i<that.getlist.length;i++){
					
				}				
			},
	  		immediate: true,
			deep: true
	  	}
	}
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.bigcapit{
		text-transform:capitalize;
	}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.v-enter {
	opacity: 0;
	transform: translateX(150px);
}

.v-leave-to {
	opacity: 0;
	transform: translateX(-150px);
}

.v-enter-active,
.v-leave-active {
	transition: all 0.5s ease;
}
.list-complete-item {
  transition: all 1s;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active for below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}
.ContentDress .ContentDressTxt{
	flex: 1;
}
</style>
