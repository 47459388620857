<template>
  <div id="app" v-cloak>
    <div class="HreadNav" v-if="menuid>0">
      <div class="container">
        <router-link to="/"><img alt="Vue logo" class="logo" src="/img/logo.png"></router-link>
        <div class="menubox">
	        <ul class="menu">
	          <li><router-link to="/" v-bind:class="menuid==1?'active':''">Home</router-link></li>
	          <li><router-link to="/getquote/processfist" v-bind:class="menuid==2?'active':''">Get a quote</router-link></li>
	          <li><router-link to="/about" v-bind:class="menuid==3?'active':''">About</router-link></li>
	          <li><router-link to="/contactus" v-bind:class="menuid==4?'active':''">Contact</router-link></li>
	          <li><router-link to="/Blog" v-bind:class="menuid==5?'active':''">Blog</router-link></li>
	        </ul>
	        <div class="MenuLeft" v-if="menuid==4">
	          Call Us:<span><a href="tel:0383951214">(03) 8395 1214</a></span>
	        </div>
	        <div v-else>
	          <router-link to="/getquote/processfist" class="quoteBtn">Make a Booking</router-link>
	        </div>
	        <a href="javascript:;" class="MenuLeft" style="display: none;">
	          Welcome, <span>John</span>
	        </a>
        </div>
        <nav class="menu_btn">
          <b></b>
          <b></b>
          <b></b>
        </nav>
      </div>
    </div>
    <router-view/>
    <pop-up-frame ref="PopUpFrame"></pop-up-frame>
  </div>
</template>

<script>	
	import popUpFrame from '@/components/popUpFrame.vue'
	import api from '@/api';
	export default {
	  name: 'app',
	  data(){
	  	return{
				menuid: 1, 
	  	}
	  },
	  components: {
			popUpFrame,
		},
	  created(){
	  	
	  },
	  mounted(){
//	  	console.log(sessionStorage.getItem("state"));
	  	var that=this;
	  	window.popUpFrame = this.$refs.PopUpFrame;
	  	this.detectUrl(this.$route.name);
	  	window.addEventListener('unload',this.saveState);
	  	window.popUpFrame.Loading(api.indexInfo()).then(res => {
				if(res.data.code == 1000) {
					that.$store.dispatch('getBasicsinfoFun',res.data.data);
				} else {
				}
			});
	  },
	  methods:{
	  	saveState(){
	      sessionStorage.setItem("state",JSON.stringify(this.$store.state))
	    },
	  	detectUrl(name){
	  		if(name.indexOf('proce')!=-1){
	  			name='getquote';
	  		}
	  		switch(name){
	  			case 'getquote':
	  				this.menuid = 2;
	  				break;
	  			case 'about':
	  				this.menuid = 3;
	  				break;
	  			case 'contactus':
	  				this.menuid = 4;
	  				break;	 
	  			case 'blog':
	  				this.menuid = 5;
	  				break;	
	  			case 'bloginfo':
	  				this.menuid = 5;
	  				break;	 
	  			case 'chat':
	  				this.menuid = -1;
	  				break;
	  			case 'code':
	  				this.menuid = -1;
	  				break;
	  			default:
	  				this.menuid = 1;
	  				break;
	  		}
	  	},
	  },
	  watch:{
	  	"$route.name":function(name){
				$(".menu_btn").removeClass("active");
				$(".menubox").removeClass("active");
	  		this.detectUrl(name);
	  	}
	  }
	}
</script>
<style lang="less">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
