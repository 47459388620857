<template>
	<div class="ProcessBox">
		<div class="ProcessCont small" :style="{transform:'translateX('+numsize+'px)'}" > 
			<div class="ProcessLi" :class="{'active': speed>0,'yes':speed>1}" data-id="1" @click="blackbtn">
				<div class="ProcessLiTxt">Move Size</div>
			</div>
			<div class="ProcessLi" :class="{'active': speed>1,'yes':speed>4}"  data-id="4" @click="blackbtn">
				<div class="ProcessLiTxt">Location</div>
			</div>
			<div class="ProcessLi" :class="{'active': speed>4,'yes':speed>5}"  data-id="5" @click="blackbtn">
				<div class="ProcessLiTxt">When</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    speed: Number,
    numsize:Number
  },
  data(){
  	return{
  	}
  },
  methods:{
  	blackbtn(e){
  		var that=this;
  		var Layoutid=e.srcElement.dataset.id;
  		if(Layoutid<this.speed){
  			this.$emit('speed',Layoutid);
			this.$store.dispatch('getLayoutFun',Layoutid);
  			switch(Layoutid){
				case "1":
					that.$router.push('/getquote/processfist');
					break;
				case "4":	
					that.$router.push('/getquote/processfrom');
					break;
				case "5":	
					that.$router.push('/getquote/processdate');
					break;
			}
  		}
  	}
  },
  mounted(){
  }  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
