<template>
	<div class="ProcessBody">
		<div class="HomeBody NewPadd">
	  		<div class="container">
	  			<h1 class="ProcessLittle nomorlpadd">
	  				Items
				</h1>
				<!--<div class="AnmartionBox">
					<img src="/img/Track.png" class="Track">
					<img src="/img/balloon.png" class="balloon">
					<img src="/img/smallcar.png" class="CarIcon">
				</div>-->
				<processbottom :DefaultState="$store.state.DefaultAnimationState" :item="true" :AnimationList="$store.state.getlist" :houseType="$store.state.processdata.GetHouseType" />
				<div class="pcshow">	
					<div class="ItemBox">
						<div class="ItemLeft">
							<h1>Category</h1>
							<div class="ItemList">
								<div class="ItemListHread" v-for="(Item,index) in Itembox">
									<img  :src="'/img/'+Item.IconName+'.png'">
									<div class="ItemHreadBox" :class="ItemBoxIndex==index?'active':''">
										<div class="ItemHreadTxt" @click="ItemClick(index)">
											<span>{{Item.Name}}</span>
										</div>
										<div class="ItemHreadBody">
											<!--三级-->
											<div class="ItemBodyBox" v-for="(Itemsecond,indexsecond) in Item.Items">
												<!--三级-->
												<div v-if="Itemsecond.Items" class="ItemRow">
													<div class="ItemBodyBoxLeft">{{Itemsecond.Name}}</div>
													<div class="ItemBodyBoxrightBox">
														<a class="ItemBodyBoxright" v-for="(Itemlast,indexlast) in Itemsecond.Items"  @click="ItemBody(Itemlast.ItemName,Itemlast.ItemId,index,indexsecond,indexlast)"  href="javascript:;">
															<span>{{Itemlast.ItemName}}</span>
															<img src="/img/add.png">
														</a>													
													</div>
												</div>	
												<div class="Itemwidth" v-else>
													<div class="ItemBodyBoxrightBox">
														<a class="ItemBodyBoxright"   @click="ItemBody(Itemsecond.ItemName,Itemsecond.ItemId,index,indexsecond)"  href="javascript:;">
															<span>{{Itemsecond.ItemName}}</span>
															<img src="/img/add.png">
														</a>													
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="ItemRight">
							<div class="ItemRightBig">
								<h1>
									Your items
								</h1>
								<div class="ItemRightBox">
									<div class="ItemBoxLi" v-for="(data,index) in ItemsTxt">
										<span>{{data.name}}</span>
										<div class="BoxSetting">
											<a href="javascript:;" @click="reduce(index,data)">-</a>
											<span>{{data.num}}</span>
											<a href="javascript:;" @click="add(index,data)">+</a>
										</div>
									</div>
								</div>
							</div>	
							<p class="red" v-if="itemstate">Please select item</p>
							<a href="javascript:;" class="ItemRightBtn" @click="Next()">
								Continue
							</a>
						</div>
					</div>
				</div>	
				<div class="moblieshow zIndex">
					<div class="ItemBoxHread">
						<span>Category</span>
						<a href="javascript:;" @click="Checkitems()">Check Your items</a>
					</div>
					<div class="Itemmobliebox">
						<ul>
							<li v-for="(Item,index) in Itembox">
								<div class="ItemLi" @click="processbtn(index)">
									<img  :src="'/img/'+Item.IconName+'.png'">
									<h1>{{Item.Name}}</h1>
								</div>
							</li>
						</ul>
					</div>
					<p class="red" v-if="itemstate">Please select item</p>
					<a href="javascript:;" class="ItemRightBtnnew" @click="Next()">
						Continue
					</a>
				</div>
	    	</div>
	  	</div>
	  	<!--弹出框-->
	  	<div class="pop" :class="(items||youritems)?'active':''" @click="pop()"></div>
	  	<div class="bedroompop" :class="items?'active':''" v-if="ItemBoxIndex>-1">
	  		<h1 class="roomlittle">
	  			{{Itembox[ItemBoxIndex].Name}}
	  		</h1>
	  		<div class="roombody">
	  			<div class="roombodybox" v-for="(item,index) in Itembox[ItemBoxIndex].Items" :key="index">
	  				<div v-if="item.Items" class="ItemRow Itemwidth">
		  				<div class="roomboxleft">
		  					{{item.Name}}
	  					</div>
	  					<div class="roomboxright">
							<ul>
								<li v-for="(childenitem,childenindex) in item.Items" :key="">
									<span>{{childenitem.ItemName}}</span>
									<a href="javascript:;" class="moreadd"   @click="moblieadd(index,childenitem.ItemName,childenitem.ItemId,childenindex)" v-if="childenitem.num<1"><img src="/img/add.png"></a>
									<div class="BoxSetting" v-else>
										<a href="javascript:;" @click="phonereduce(index,childenitem.ItemId,childenindex)">-</a>
										<span>{{childenitem.num}}</span>
										<a href="javascript:;" @click="phoneadd(index,childenitem.ItemId,childenindex)">+</a>
									</div>
								</li>						
							</ul>  						
	  					</div>
	  				</div>	
	  				<div v-else>
	  					<div class="roomboxright">
							<ul>
								<li>
									<span>{{item.ItemName}}</span>
									<a href="javascript:;" class="moreadd"   @click="moblieadd(index,item.ItemName,item.ItemId,childenindex)" v-if="item.num<1"><img src="/img/add.png"></a>
									<div class="BoxSetting" v-else>
										<a href="javascript:;" @click="phonereduce(index,item.ItemId,childenindex)">-</a>
										<span>{{item.num}}</span>
										<a href="javascript:;" @click="phoneadd(index,item.ItemId,childenindex)">+</a>
									</div>
								</li>						
							</ul>  						
	  					</div>
	  				</div>
	  			</div>
	  		</div>
	  	</div>
	  	
	  	<div class="bedroompop" :class="youritems?'active':''">
	  		<h1 class="roomlittle">
	  			Your Items
	  		</h1>
	  		<div class="roombody">
	  			<div class="roombodybox flexend" v-for="(data,index) in ItemsTxt">
	  				<div class="roomboxleft flexend">
	  					{{data.name}}
  					</div>
  					<div class="roomboxright flexend">
						<div class="BoxSetting">
							<a href="javascript:;" @click="reduce(index,data)">-</a>
							<span>{{data.num}}</span>
							<a href="javascript:;" @click="add(index,data)">+</a>
						</div>
  					</div>
	  			</div>
	  		</div>
	  	</div>
	</div>
</template>

<script>
	import processbottom from '@/components/processbottom.vue'
export default {
  name: 'HelloWorld',
  components: {
	processbottom,
  },
  data() {
		return {
			ItemBoxIndex:-1,
			ItemsTxt:[],
			Itembox:[],
			items:false,
			youritems:false,
			itemstate:false
		}
  },
  methods:{
		ItemClick(index){
			if(this.ItemBoxIndex==index){
				this.ItemBoxIndex=-1;
			}else{
				this.ItemBoxIndex=index;
			}
		},
		Itemli(id){
			for(var i=0;i<this.ItemsTxt.length;i++){
				if(this.ItemsTxt[i].id==id){
					return true;
				}
			}
		},
		Itemid(id){
			for(var i=0;i<this.ItemsTxt.length;i++){
				if(this.ItemsTxt[i].id==id){
					return i;
				}
			}
		},
		ItemBody(e,id,index,indexsecond,indexlast){
			this.itemstate=false;
			var Itemdata={
				id:id,
				name:e,
				num:1,
				index:index,
				indexsecond:indexsecond,
				indexlast:indexlast>-1?indexlast:-1,
			}
			if(this.ItemsTxt.length>0){//如果已存在就判断是否是现有item集合里面的
				var Itemstatus=this.Itemli(id);
				if(!Itemstatus){
					this.Itembox[index].sel=true;
					if(indexlast>-1){
						this.Itembox[index].Items[indexsecond].sel=true;
						this.Itembox[index].Items[indexsecond].Items[indexlast].num++;
					}else{
						this.Itembox[index].Items[indexsecond].num++;
					}					
					this.ItemsTxt.push(Itemdata);
				}
			}else{
				this.Itembox[index].sel=true;
				if(indexlast>-1){
					this.Itembox[index].Items[indexsecond].sel=true;
					this.Itembox[index].Items[indexsecond].Items[indexlast].num++;
				}else{
					this.Itembox[index].Items[indexsecond].num++;
				}	
				this.ItemsTxt.push(Itemdata);
			}
		},
		phonereduce(index,childenid,childenindex){
			if(this.ItemsTxt[index].num>1){
				if(childenindex>-1){
					this.Itembox[this.ItemBoxIndex].Items[index].Items[childenindex].num--;
				}else{
					this.Itembox[this.ItemBoxIndex].Items[index].num--;
				}
				let itemsindex=this.Itemid(childenid);
				this.ItemsTxt[itemsindex].num--;
			}else{
				this.Itembox[this.ItemBoxIndex].sel=false;
				if(childenindex>-1){
					this.Itembox[this.ItemBoxIndex].Items[index].sel=false;
					this.Itembox[this.ItemBoxIndex].Items[index].Items[childenindex].num=0;
				}else{
					this.Itembox[this.ItemBoxIndex].Items[index].num=0;
				}
				let itemsindex=this.Itemid(childenid);
				this.ItemsTxt.splice(itemsindex,1); 
			}
		},
		phoneadd(index,childenid,childenindex){
			let itemsindex=this.Itemid(childenid);
			this.ItemsTxt[itemsindex].num++;
			if(childenindex>-1){
				this.Itembox[this.ItemBoxIndex].Items[index].Items[childenindex].num++;
			}else{
				this.Itembox[this.ItemBoxIndex].Items[index].num++;
			}
			
		},
		reduce(index,data){
			if(this.ItemsTxt[index].num>1){
				if(data.indexlast>-1){
					this.Itembox[data.index].Items[data.indexsecond].Items[data.indexlast].num--;
				}else{
					this.Itembox[data.index].Items[data.indexsecond].num--;
				}					
				this.ItemsTxt[index].num--;
			}else{
				this.Itembox[data.index].sel=false;
				if(data.indexlast>-1){
					this.Itembox[data.index].Items[data.indexsecond].sel=false;
					this.Itembox[data.index].Items[data.indexsecond].Items[data.indexlast].num=0;
				}else{
					this.Itembox[data.index].Items[data.indexsecond].num=0;
				}
				this.ItemsTxt.splice(index,1); 
			}
		},
		add(index,data){
			this.ItemsTxt[index].num++;
			if(data.indexlast>-1){
				this.Itembox[data.index].Items[data.indexsecond].Items[data.indexlast].num++;
			}else{
				this.Itembox[data.index].Items[data.indexsecond].num++;
			}			
		},
		moblieadd(index,name,Id,childenindex){
			this.itemstate=false;
			var Itemdata={
				id:Id,
				name:name,
				num:1,
				index:this.ItemBoxIndex,
				indexsecond:index,
				indexlast:childenindex>-1?childenindex:-1,
			}
			this.Itembox[this.ItemBoxIndex].sel=false;//设置选中集
			if(childenindex>-1){
				this.Itembox[this.ItemBoxIndex].Items[index].sel=false;//设置选中集
				this.Itembox[this.ItemBoxIndex].Items[index].Items[childenindex].num++;
			}else{
				this.Itembox[this.ItemBoxIndex].Items[index].num++;
			}
			this.ItemsTxt.push(Itemdata);
		},
		Next(){
			if(this.ItemsTxt.length<1){
				this.itemstate=true;
				return false;
			}
			var data={
				ItemsTxt:this.ItemsTxt,
				Itembox:this.Itembox
			}
			this.$store.dispatch('getitemFun',data);
	  		this.$store.dispatch('getLayoutFun',13);
	  		if(this.edit=="update"){
				this.$router.push({path:'/fullprice',params:{}});
			}else{
				if(this.$store.state.submitType==3&&this.$store.state.continuenum==0){
					this.$router.push({path:'/getquote/processcontact',params:{}});
				}else{
					this.$router.push({
						path:'/getquote/processfullpicelast',
						query:{
							'fullquote':1
						}
					});
				}
			}
	  	},
	  	processbtn(index){
	  		this.ItemBoxIndex=index;
	  		this.items=true;
	  	},
	  	Checkitems(){
	  		this.youritems=true;
	  	},
	  	pop(){
	  		this.items=false;
	  		this.youritems=false;
	  	}
	},
	mounted() {
		this.edit=this.$route.query.Edit?this.$route.query.Edit:false;
		this.$store.dispatch('setisshowFun',false);
		var HomeCategorys=JSON.parse(JSON.stringify(this.$store.state.PriceSettings.BusinessCategorys));
		var HomeItems=JSON.parse(JSON.stringify(this.$store.state.PriceSettings.BusinessItems));
		for(var i=0;i<HomeItems.length;i++){
			if(HomeItems[i].Items){
				for(var l=0;l<HomeItems[i].Items.length;l++){
					HomeItems[i].Items[l].num=0;
				}
			}
		}
		for(var i=0;i<HomeCategorys.length;i++){
			if(HomeCategorys[i].Items.length>0){
				for(var n=0;n<HomeCategorys[i].Items.length;n++){
					if(HomeCategorys[i].Items[n].Items.length>0){
						for(var l=0;l<HomeCategorys[i].Items[n].Items.length;l++){
							HomeCategorys[i].Items[n].Items[l].num=0;
						}
					}
					for(var v=0;v<HomeItems.length;v++){
						if(HomeCategorys[i].Items[n].Id==HomeItems[v].CategoryId){
							HomeCategorys[i].Items[n].Items=HomeItems[v].Items;
						}
					}
				}
			}else{
				for(var v=0;v<HomeItems.length;v++){
					if(HomeCategorys[i].Id==HomeItems[v].CategoryId){
						HomeCategorys[i].Items=HomeItems[v].Items;
					}
				}
			}
		}
		
		if(this.$store.state.getItemList){		
			this.ItemsTxt=JSON.parse(JSON.stringify(this.$store.state.getItemList));//获取store里面的数据
		}
		let getItemListitem=[];
		if(this.$store.state.getItemListitem){
			getItemListitem=JSON.parse(JSON.stringify(this.$store.state.getItemListitem));
		}
		this.Itembox=getItemListitem.length>0?getItemListitem:HomeCategorys;//获取已选择的数据
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.red{
	color: #D22E35;
	text-align: left;
	font-size: 18px;
	padding: 15px 0;
}
</style>
