<template>
	<div class="ProcessBody">
		<div class="HomeBody NewPadd">
	  		<div class="container">
	  			<h1 class="ProcessLittle nomorlpadd">
	  				Your {{processdata.inputtextall}} {{processdata.house}}
				</h1>
				<p class="ProcessSubheading">
					How far is the {{processdata.house}} from the parking area?
				</p>
				<div class="Processzero">
					<a href="javascript:;" :class="rice=='10'?'active':''" @click="ProcessBtn('10')">
						10m
					</a>
					<a href="javascript:;" :class="rice=='20'?'active':''" @click="ProcessBtn('20')">
						20m
					</a>
					<a href="javascript:;" :class="rice=='30'?'active':''" @click="ProcessBtn('30')">
						30m
					</a>
					<a href="javascript:;" :class="rice=='40'?'active':''" @click="ProcessBtn('40')">
						40m
					</a>
				</div>
	    	</div>
	  	</div>
	</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    getlist:Array,
  },
  data() {
		return {
			Layout: 1, 
			hread:1,
			comName:'processto',
			list:[],
			index:0,
			rice:"",
			processdata:{},
		}
	},
	methods:{
		ProcessBtn(fromrice){
//			this.$emit('func',fromtype);
//			this.$parent.fatherMethod(fromrice);
			this.$store.dispatch('fatherMethodFun',fromrice);
		}
	},
	mounted() {
		this.$store.dispatch('setisshowFun',true);
		this.processdata=this.$store.state.getlist[this.$store.state.parkingindex];
		this.rice=this.$store.state.getlist[this.$store.state.parkingindex].rice;
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
