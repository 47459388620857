<template>
	<div class="editbox">
		<div class="container">
			<div class="AddressBox newline">
				<div class="AddressTxt">
					<div class="AddTxt" v-for="(element, idx) in editlist">
						{{element.sceentype}}
					</div>
				</div>
				<div class="AddressIcon">
					<div class="AddIcon" v-for="(element, idx) in editlist">
						<img src="/img/smallhouse.png" v-if="element.housetype==1">
						<img src="/img/smallment.png" v-if="element.housetype==2">
						<img src="/img/smallfacility.png" v-if="element.housetype==3" >
					</div>
				</div>
				<div class="AddressRegion">
					<div class="AddressRegionLeft" >
						<h1></h1>
						<p v-for="(item, idx) in editlist" :class="parkingindex==idx?'colorD22E35':''" v-html="item.inputtext"></p>
						<!--<p>Clayton</p>-->
					</div>
					<div class="AddressRegionLeft" >
						<h1>
							Parking Area
						</h1>
						<p class="showcolor" v-for="(element, idx) in editlist">
							{{element.rice}}
						</p>
					</div>
					<div class="AddressRegionLeft" >
						<h1>
							Storeys/Floors
						</h1>
						<p class="showcolor" v-for="(element, idx) in editlist">
							{{element.floor>'0'?element.floor:''}}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>	
</template>

<script>
	export default {
	  name: "handle",
	  props:{
	  	editlist:Array,
	  	parkingindex:Number,
	  },
	  data() {
	    return {
	    };
	  },
	  computed: {
	  },
	  methods: {
	  	
	  }
	};
</script>

<style>
</style>