<template>
  <div class="about">
  		<div class="ContBanner">
			<!-- <iframe width="1920" height="1080" src="https://www.youtube.com/embed/_tgreMyFVaE?playlist=_tgreMyFVaE&iv_load_policy=3&enablejsapi=1&disablekb=1&autoplay=1&controls=0&showinfo=0&rel=0&loop=1&widgetid=1" title="台式钢琴搬运，全放那位保护钢琴！墨尔本爱家搬运" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
			<!-- <iframe id="ytplayer" type="text/html" width="100%" height="100%" src="https://www.youtube.com/embed/_tgreMyFVaE?playlist=_tgreMyFVaE&iv_load_policy=3&enablejsapi=1&disablekb=1&autoplay=1&controls=0&showinfo=0&rel=0&loop=1&widgetid=1" title="台式钢琴搬运，全放那位保护钢琴！墨尔本爱家搬运" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" style="max-width: 1000%; margin-left: 0px; margin-top: -285px; width: 1920px; height: 1080px;" allowfullscreen></iframe> -->
			<iframe class="inner" frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="台式钢琴搬运，全放那位保护钢琴！墨尔本爱家搬运" width="100%" height="100%" src="https://www.youtube.com/embed/_tgreMyFVaE?playlist=_tgreMyFVaE&amp;iv_load_policy=3&amp;enablejsapi=1&amp;disablekb=1&amp;autoplay=1&amp;controls=0&amp;showinfo=0&amp;rel=0&amp;loop=1&amp;origin=https%3A%2F%2Fwww.lovehomemove.au&amp;widgetid=1&amp;mute=1" id="widget2" style="max-width: 1000%; margin-left: 0px; margin-top: -285px; width: 1920px; height: 1080px;"></iframe>
		</div>
  		<div class="AboutTxt">
  			<div class="container">
  				<h1>Contact your local Melbourne movers iMover Today</h1>
  				<div class="ContBox">
  					<div class="ContBoxLeft">
  						<h2>Phone</h2>
  						<p>You can phone our offices on </p>
  						<p class="color137">03 8395 1214</p>
							<h2 class="margintop">Email</h2>
							<p>
								If you would like to get an estimate of times and possible costs for your move, the quickest and best way to do so is via our online booking request form. It is super easy to fill out, and will give us all the information we need to put an accurate estimate together for your job – and from there, you can decide if you’d like to book in! 
							</p>
							<p class="margintopTxt">
								For any other enquiries, you can get in touch via email at <span class="color137">info@imover.com.au</span>
							</p>
  					</div>
  					<div class="ContBoxRight">
  						<h2>Quick Contact</h2>
  						<div class="ContBoxFrom">
  							<div class="frominput" :class="errortxt=='Contact'?'redinput':''">
	  							<p>Name<span>*</span></p>
	  							<input type="text" v-model="fromdate.Contact">
  							<p class="red">{{erroriponetxt}}</p>
	  						</div>	
	  						<div class="frominput" :class="errortxt=='Email'?'redinput':''">
	  							<p>Email<span>*</span></p>
	  							<input type="text" v-model="fromdate.Email">
  								<p class="red">{{erroriponetxt}}</p>
	  						</div>
	  						<div class="frominput" :class="errortxt=='Phone'?'redinput':''">
	  							<p>Phone<span>*</span></p>
	  							<input type="text" v-model="fromdate.Phone">
  								<p class="red">{{erroriponetxt}}</p>
	  						</div>
	  						<div class="frominput" :class="errortxt=='Content'?'redinput':''">
	  							<p>Your message<span>*</span></p>
  								<textarea  class="Rightext" v-model="fromdate.Content"></textarea>
  								<p class="red">{{erroriponetxt}}</p>
	  						</div>
  							<a href="javascript:;" class="RightBtn" @click="getcontact()">
  								Get in touch
  							</a>
  						</div>
  					</div>
  				</div>
  			</div>
  		</div>
    	<homebottom />
  </div>
</template>

<script>
	import popUpFrame from '@/components/popUpFrame.vue'
import homebottom from '@/components/homebottom.vue'
import api from '@/api'
// @ is an alias to /src

export default {
  name: 'About',
  data(){
  	return{
  		errortxt:'',
  		erroriponetxt:'This field is required',
  		fromdate:{
  			Contact:'',
	  		Email:'',
	  		Phone:'',
	  		Content:'',
  		}
  	}
  },
  components: {
    homebottom
  },
  methods:{
  	getcontact(){
  		let _that=this;
  		let emailcode=new RegExp("^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$");
  		if(_that.fromdate.Contact==""){
		  	_that.errortxt='Contact';
  			_that.erroriponetxt='This field is required';
		    return false;
		  }
  		if(_that.fromdate.Email==""){
  			_that.errortxt='Email';
  			_that.erroriponetxt='This field is required';
		    return false;
		  }
  		if(!emailcode.test(_that.fromdate.Email)){
  			_that.errortxt='Email';
  			_that.erroriponetxt='Please enter a valid email';
  			return;
  		}
  		if(_that.fromdate.Phone==""){
		  	_that.errortxt='Phone';
  			_that.erroriponetxt='This field is required';
		    return false;
		  }
  		if(_that.fromdate.Content==""){
		  	_that.errortxt='Content';
  			_that.erroriponetxt='This field is required';
		    return false;
		  }
		  window.popUpFrame.Loading(api.quickcontact(_that.fromdate)).then(res => {
				if(res.data.code == 1000) {
					alert('留言成功');
				} else {
					alert(res.data.message);
				}
			});
  	}
  },
  mounted() {
	},
}
</script>


<style scoped lang="less">
	h3 {
	  margin: 40px 0 0;
	}
	ul {
	  list-style-type: none;
	  padding: 0;
	}
	li {
	  display: inline-block;
	  margin: 0 10px;
	}
	.red{
		color: #D22E35;
		font-size: 14px;
		display: none;
	}
	.frominput textarea{
		font-family:'Arial';
		padding-top: 10px;
	}
	.redinput input,.redinput textarea{
		border-color:#D22E35;
	}
	.redinput .red{
		display: block;
	}
</style>