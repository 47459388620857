import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import globalval from '@/components/global.vue'
import Utils from './common/utils.js';

	
Vue.use(VueAwesomeSwiper);
　　
Vue.prototype.GLOBAL = globalval;
Vue.prototype.utils=Utils;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
