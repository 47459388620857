<template>
  <div class="about" v-cloak>
  		<div class="AboutTxt">
  			<div class="container" v-if="orderInfo.Order">
  				<div class="FullPiceBox">
  					<img src="../../public/img/CheckIconActive.png" class="SucessIcon">
  					<h1 class="moblieLittle">
						Thanks, {{orderInfo.Order.Contact}}. The quote has been sent to your email!
  					</h1>
  					<p>
						Please check your email {{orderInfo.Order.Email}} for the quick quote.
  					</p>
  				</div>
				<div class="FullEdit">
					<div class="FullBtnBox">
						<a href="javascript:;" class="calendar" @click="next()">
							<span class="icon"></span><span>Make a booking</span>
						</a>
						<!-- <a href="javascript:;" @click="fullQuoteFun(3)" class="email" v-if="fullquote==1">
    					<span class="icon"></span><span>Continue to full quote</span>
    				</a> -->
					</div>
				</div>
  			</div>
  		</div>
  		
    	<homebottom />
  </div>
</template>

<script>
import api from '@/api'
import homebottom from '@/components/homebottom.vue'
// @ is an alias to /src

export default {
  name: 'sucess',
  data(){
  	return{
  		orderInfo:{},
		fullquote:0,
  	}
  },
  components: {
    homebottom
  },
	created:function(){
		var that=this;
		this.$nextTick(function(){
	  		that.Info();
	  	})
	},
	mounted(){
		this.fullquote=this.$route.query.fullquote?this.$route.query.fullquote:0;
	},
	methods:{
		Info(){
			var that=this;
			window.popUpFrame.Loading(api.getorderInfo(that.$route.query.orderid)).then(res => {
				if(res.data.code == 1000) {
					that.orderInfo=res.data.data;
				} else {
				}
			});
		},		
		fullQuoteFun(name){
			this.$store.dispatch('getContinueFun',1);
			this.$store.dispatch('processfullpiceFun',name);
			this.$store.dispatch('setisshowFun',true);
			this.$router.push('/getquote/processparking');
		},
		next(){
			if(this.fullquote==1){
				this.$router.push({
					path: '/fullprice',				
					query:{
						'quotetype':1
					}
				});
			}else{
				this.$router.push({
					path: '/fullprice',				
					query:{
					}
				});
			}			
		}
	}
}
</script>