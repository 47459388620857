<template>
	<div class="ProcessBox morepadd">
		<div class="ProcessCont MoreWidth">
			<div class="ProcessLi" :class="{'active': speed>0,'yes':speed>1}" data-id="1" @click="blackbtn">
				<div class="ProcessLiTxt">Move Size</div>
			</div>
			<div class="ProcessLi" :class="{'active': speed>1,'yes':speed>4}"  data-id="4" @click="blackbtn">
				<div class="ProcessLiTxt">Location</div>
			</div>
			<div class="ProcessLi" :class="{'active': speed>4,'yes':speed>5}"  data-id="5" @click="blackbtn">
				<div class="ProcessLiTxt">When</div>
			</div>
			<div class="ProcessLi" :class="{'active': speed>4,'yes':speed>5}" data-id="6" @click="blackbtn">
				<div class="ProcessLiTxt">Quick quote</div>
			</div>
			<div class="ProcessLi" :class="{'active': speed>6,'yes':speed>10,'noclick':$store.state.submitType==2||$store.state.submitType==1}" data-id="7" @click="blackbtn">
				<div class="ProcessLiTxt">Access</div>
			</div>
			<div class="ProcessLi" :class="{'active': speed>10,'yes':speed>12,'noclick':$store.state.submitType==2||$store.state.submitType==1}"  :data-id="SelHouse=='home'?11:12" @click="blackbtn">
				<div class="ProcessLiTxt">Items</div>
			</div>
			<div class="ProcessLi" :class="{'active': speed>12,'yes':speed>13}" data-id="13" @click="blackbtn">
				<div class="ProcessLiTxt">Book</div>
			</div>
		</div>
	</div>
</template> 

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
    SelHouse:String,
    speed: Number,
    numsize:Number
  },
  methods:{
  	blackbtn(e){
  		var Layoutid=e.srcElement.dataset.id;
  		if(Layoutid<this.speed){
  			var that=this;
  			
  			switch(Layoutid){
				case "1":				
					that.$store.dispatch('getHreadFun',1);
					that.$router.push('/getquote/processfist');
					break;
				case "4":	
					that.$store.dispatch('getHreadFun',1);
					that.$router.push('/getquote/processfrom');
					break;
				case "5":	
					that.$store.dispatch('getHreadFun',1);
					that.$router.push('/getquote/processdate');
					break;
				case "6":	
					that.$router.push('/getquote/processfullpice');
					break;
				case "7":	
					if(that.$store.state.submitType==2||that.$store.state.submitType==1){
						return;
					}
					that.$router.push('/getquote/processparking');
					break;
				case "11":	
					if(that.$store.state.submitType==2||that.$store.state.submitType==1){
						return;
					}
					that.$router.push('/getquote/processitems');
					break;
				case "12":	
					if(that.$store.state.submitType==2||that.$store.state.submitType==1){
						return;
					}
					that.$router.push('/getquote/processbusinessitems');
					break;
				case "13":	
					that.$router.push('/getquote/processcontact');
					break;
			}
  			this.$emit('speed',Layoutid);  			
			this.$store.dispatch('getLayoutFun',Layoutid);
  		}
  	}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.ProcessBox .ProcessCont .ProcessLi.active.yes.noclick{
	cursor: auto;
}
</style>
