<template>
  <div class="home">
    <banner />
    <homebody />
    <homebottom />
    <!--<div class="zhezhaocen"></div>
    <div class="videoHome">
    	<a href="javascript:;" class="closeVideo">x</a>
    	<iframe width="100%" height="400px"  src="https://www.youtube.com/embed/Q3wDUMCmIOQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>-->
    <!--<iframe width="1280" height="720" src="https://www.youtube.com/embed/Q3wDUMCmIOQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
  </div>
</template>

<script>
// @ is an alias to /src
import banner from '@/components/banner.vue'
import homebody from '@/components/homebody.vue'
import homebottom from '@/components/homebottom.vue'
import api from '@/api';

export default {
  name: 'Home',
  data() {
			return {
				
			}
	},
  components: {
    banner,
    homebody,
    homebottom
  },
  mounted() {
	  this.$store.commit('fullItemEdit',false);
	  this.$store.dispatch('progressiconFun',true);
	  this.$store.dispatch('getOnlyDateMethodFun', '');
	  this.$store.dispatch('getOrderIdFun',0);
  	this.$store.dispatch('getBusinessitemFun',{});
		this.$store.dispatch('getitemFun',{});
		this.$store.dispatch('getContactFun',{});
  	this.$store.dispatch('setisshowFun',false);
		this.$store.dispatch('getContinueFun',0);
		this.$store.dispatch('getLayoutFun',1);
		this.$store.dispatch('getHreadFun',1);
		this.$store.dispatch('getlistoperaFun',[]);
		this.$store.dispatch('getsubimeStateFun',false);
	},
	methods:{
		
	}
}

</script>
<style scoped="scoped">
	.zhezhaocen{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 90;
		background: rgba(0,0,0,.3);
	}
	.videoHome{
		position: fixed;
		top: 50%;
		left: 50%;
		right: 0;
		bottom: 0;
		z-index: 91;
		width: 600px;
		height: 400px;
		transform: translate(-50%,-50%);
		-webkit-transform: translate(-50%,-50%);
		-moz-transform: translate(-50%,-50%);
		padding: 50px;
		background: #fff;
	}
	.videoHome iframe{
		width: 100%;
	}
	.closeVideo{
		color: #000;
		font-size: 30px;
		position: absolute;
		top: 20px;
		right: 20px;
	}
	.v-enter,
    .v-leave-to {
      opacity: 0;
      transform: translateX(150px);
    }

    .v-enter-active,
    .v-leave-active {
      transition: all 0.5s ease;
    }
</style>
